<template>
  <div class="page-list">
    <div class="list">
      <ul v-for="item of list" :key="item.id" @click="handleNavDetail(item)">
        <li class="name">
          <span>
            {{ item.name }}
          </span>
          <div class="tag">
            <span class="free" v-if="item.is_free === 1">{{
              i18n.Mock.free
            }}</span>
            <span class="have" v-if="item.has_exam === 1">{{
              i18n.Mock.Practiced
            }}</span>
          </div>
        </li>
        <li class="tools">
          <el-button size="small" round class="btn">
            {{ item.has_exam === 1 ? i18n.Mock.again : i18n.Mock.model }}
          </el-button>
        </li>
      </ul>
    </div>

    <div class="pagination">
      <el-pagination
        small
        layout="prev, pager, next"
        :current-page="pageIndex"
        :total="total"
        @current-change="handlePage"
      />
    </div>

    <VipTip ref="vipTip" />
  </div>
</template>

<script>
import api from '@/api/exam'
import { mapState } from 'vuex'
import VipTip from './vipTip'

export default {
  components: {
    VipTip
  },
  data() {
    return {
      pageIndex: 1,
      total: 0,
      list: []
    }
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo
    }),
    i18n() {
      return this.$t('messages')
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    async fetchList() {
      const {
        data: {
          list,
          pager: { total_cnt }
        }
      } = await api.list({
        page: this.pageIndex,
        page_size: 10
      })

      this.list = list
      this.total = total_cnt
    },
    handlePage(page) {
      this.pageIndex = page
      this.fetchList()
    },
    async handleNavDetail(item) {
      if (
        item.is_free != 1 &&
        (!this.userInfo.vip || this.userInfo.vip.code == 0)
      ) {
        return this.$refs.vipTip.open()
      }

      const { data, code } = await api.addExam({
        exam_id: item.id
      })

      if (code !== 200) {
        return
      }

      window.open(
        this.$router.resolve({
          name: 'examTest',
          query: {
            id: item.id,
            examId: data.my_exam_id
          }
        }).href,
        '_blank'
      )
    }
  }
}
</script>

<style scoped lang="scss">
.page-list {
  .list {
    margin-top: 12px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    ul {
      display: flex;
      align-items: center;
      padding: 0 38px;
      position: relative;
      cursor: pointer;
      &:hover {
        .name {
          > span {
            color: #3b86ff;
          }
        }
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 40px;
        width: calc(100% - 80px);
        height: 1px;
        background-color: #ebebf2;
      }
      > li {
        padding: 18px 0;
        text-align: center;
        line-height: 1.2;
        color: #1f1f40;
        font-size: 16px;
        &.name {
          flex: 1;
          display: flex;
          > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            max-width: 600px;
          }
          > .tag {
            margin-left: 10px;
            > span {
              margin-left: 6px;
              padding: 0 10px;
              font-size: 12px;
              line-height: 16px;
              border-radius: 10px;
              &.free {
                background-color: #ffd2dd;
                color: #b71c1c;
              }
              &.have {
                background-color: #d4fcf2;
                color: #2b8a7b;
              }
            }
          }
        }
        &.tools {
          width: 80px;
          flex: none;
        }
        .split {
          margin: 0 8px;
        }
        .btn {
          height: 34px;
          min-width: 92px;
          font-size: 14px;
          background-color: #ededed;
          border: none;
          &:hover {
            color: #3b86ff;
            background-color: #c9e2ff;
          }
        }
      }
    }
  }

  .pagination {
    text-align: center;
    margin-top: 15px;
    ::v-deep .el-pagination--small {
      .el-pager li,
      .btn-prev,
      .btn-next {
        border: 1px solid rgba(199, 202, 222, 1);
        border-radius: 3px;
        margin: 0 3px;
        color: #c7cade;
      }
      .el-pager li.active {
        border: 1px solid rgba(59, 134, 255, 1);
        color: #3b86ff;
      }
      .btn-prev,
      .btn-next {
        padding: 0 4px;
      }
      .el-pager li.btn-quicknext,
      .el-pager li.btn-quickprev {
        border: none;
      }
    }
  }
}
</style>
